/**
 * @module
 * Home page entry point.
 */
import {
    MiniPublicUserInfo,
} from '@lib/github';
import renderClient from '@webui/renderClient';
import {
    authLoginRoute,
    homeRoute,
    repoSettingsRoute,
} from '@webui/routes';
import {
    Template,
    TemplateProps,
} from '@webui/template';
import React from 'react';
import {
    Alert,
    Button,
    Container,
    Form,
    Input,
    ListGroup,
    ListGroupItem,
} from 'reactstrap';

export interface DashboardInstallation {
    id: number;
    account: MiniPublicUserInfo;
    repos: { owner: string; name: string; disabled: boolean; }[];
}

export interface HomeNoLoginProps extends TemplateProps {
    homeState: 'NoLogin';
}

/**
 * Shown then the user has not logged in.
 */
export function HomeNoLogin(props: HomeNoLoginProps): JSX.Element {
    const { children, ...templateProps } = props;
    const authLoginPath = authLoginRoute.toPath({}, props.mountPath);
    return <Template {...templateProps}>
        <header className='jumbotron jumbotron-fluid'
                style={{backgroundColor: 'transparent'}}>
            <Container className='text-center'>
                <h1 className='display-3'>CanIHasReview</h1>
                <p className='lead'>GitHub Pull Request versioning utility.</p>
                <hr className='my-4' />
                To start, <Button href={authLoginPath} outline color='primary'>Login with GitHub</Button>
            </Container>
        </header>
    </Template>;
}

export interface HomeDashboardProps extends TemplateProps {
    homeState: 'Dashboard';
    installations: DashboardInstallation[];
}

export function HomeDashboard(props: HomeDashboardProps): JSX.Element {
    const { children, ...templateProps } = props;
    const homePath = homeRoute.toPath({}, props.mountPath);
    const items = props.installations.map(installation => {
        const repos = installation.repos.map(repo => {
            const fullName = `${repo.owner}/${repo.name}`;
            const repoSettingsPath = repoSettingsRoute.toPath({ owner: repo.owner, repo: repo.name }, props.mountPath);
            return <ListGroupItem key={fullName} color={repo.disabled ? 'secondary' : undefined}>
                <div className='d-flex'>
                    <div className='flex-grow-1'>{fullName}</div>
                    <div><Form action={homePath} method='post' encType='application/x-www-form-urlencoded'>
                        <Input type='hidden' name='action' value={repo.disabled ? 'enable' : 'disable'} />
                        <Input type='hidden' name='owner' value={repo.owner} />
                        <Input type='hidden' name='repo' value={repo.name} />
                        <Button outline color={repo.disabled ? 'primary' : 'danger'} className='mx-1'>
                            {repo.disabled ? 'Enable CanIHasReview' : 'Disable CanIHasReview'}
                        </Button>
                    </Form></div>
                    {repo.disabled ? null : <div><Button href={repoSettingsPath} className='mx-1'>
                        Settings</Button>
                    </div>}
                </div>
            </ListGroupItem>;
        });
        return <div key={`install${installation.id}`}>
            <h2>{installation.account.login}'s repositories</h2>
            <ListGroup className='my-4'>{repos}</ListGroup>
        </div>;
    });
    return <Template {...templateProps}>
        <Container className='mt-5'>
            <h1>Repositories</h1>
            <p>This page lists all repositories CanIHasReview has access to.</p>
            <p>Can't find your repository? Make sure CanIhasReview has been{' '}
            <a href='https://github.com/apps/canihasreview/installations/new'>installed</a>.</p>
            {items.length > 0 ? <ListGroup>{items}</ListGroup> : <Alert color='secondary'>No installations.</Alert>}
        </Container>
    </Template>;
}

export function Home(props: HomeNoLoginProps | HomeDashboardProps): JSX.Element {
    if (props.homeState === 'NoLogin') {
        return <HomeNoLogin {...props} />;
    } else {
        return <HomeDashboard {...props} />;
    }
}

export default Home;

if (require.main && String(require.main.i) === String(module.id)) {
    renderClient(Home);
}
